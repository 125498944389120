<template>
  <div class="violations-modal-create">
    <div class="violations-modal-create__block">
      <r-text>Вид нарушения</r-text>
      <el-select
        v-model="violationType"
        class="r-select"
        filterable
        clearable
        placeholder="Выберите тип"
      >
        <el-option
          v-for="item in violationTypes"
          :key="item.id"
          :value="item.id"
          :label="item.name"
        />
      </el-select>
    </div>

    <r-button
      type="primary"
      class="violations-modal-create__button"
      :disabled="disabled"
      :loading="loading"
      @click="createViolation"
    >
      Подтвердить
    </r-button>
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'
import { sources } from '../../../configs'

export default {
  props: {
    source: {
      type: Object,
      required: true
    },
    copySource: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      violationType: null,
      sources
    }
  },
  computed: {
    violationTypes() {
      return this.source?.related?.type?.data || []
    },
    disabled() {
      return !this.violationType
    }
  },
  methods: {
    async createViolation() {
      this.loading = true

      try {
        const data = {
          type_id: this.violationType,
          violation_id: this.copySource
        }
        const { data: response } = await this.$store.dispatch('POST_REQUEST', {
          url: 'BddViolation?is_copy',
          data
        })

        if (!response?.id) return

        this.$notify(notifyFactory(
          'succcess',
          'Сохранение выполнено успешно')
        )
        this.$store.commit('BDD_SET_FIELD', {
          field: this.sources.updateField,
          value: true
        })

        this.$store.commit('CLOSE_MODAL_WINDOW')
        this.$store.commit('BDD_SET_FIELD', {
          field: 'openAfterUpdate',
          value: response?.id
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.violations-modal-create {
  display: grid;
  grid-gap: 1rem;
  align-content: start;

  &__block {
    display: grid;
    grid-gap: 0.5rem;
    align-content: start;
  }

  &__row {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    justify-content: start;
    align-items: center;
  }

  &__button {
    justify-self: start;
  }
}
</style>
